import React from 'react';

import Layout   from '../components/common/layout';
import Footer   from '../components/common/Footer';
import Sidebar  from '../components/common/Sidebar';

import Header from '../components/common/Header';
import Notice      from '../components/common/notice';

import Introduction from '../components/introduction/Introduction';
//import Features     from '../components/common/featureSlider';
import Features from '../components/features/Features';
import Gallery from '../components/common/Gallery';
import Works from '../components/common/works'
import HomeQuery from '../components/common/homequery';
import Review from '../components/common/review'
import { Helmet } from "react-helmet";
const IndexPage = () =>

<Layout>
{/* <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"/> */}
  <Header />
    <Helmet>
        <meta charset="utf-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />        
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=yes" />
        <meta name="author" content="LaundryToGo - We Make Laundry Easy" />
        <meta name="description" content="We pick up your dirty laundry and bring it back to you clean, folded and sorted, all on the same day" />      
        <title>LaundryToGo - Home</title>
    </Helmet>
    <div id="wrapper">
      {/* <Notice /> */}
      <Introduction />
      {/* <Features /> */}
      <Gallery />
      
      <Review />
      {/* <Works /> */}
     <HomeQuery />
    </div>
    <Footer bg={"blueFooter"} />
  </Layout>

export default IndexPage;
